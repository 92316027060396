// import * as React from "react";
// import { Component } from "react";
import React from "react";
import Wallet from "./Wallet";
import logo from "../Images/logo.png";
import discordLogo from "../Images/discord-logo.svg";
import twitterLogo from "../Images/twitter-logo.svg";

import { Navbar, Container, Nav } from "react-bootstrap";

class Navigation extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Navbar className="" expand="lg">
          <Container>
            <Navbar.Brand href="#home">
              <img
                alt="Logo"
                src={logo}
                width="60px"
                className="d-inline-block align-top"
              />{" "}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav className="align-items-center">
                <Nav.Link href="#link">Attributes</Nav.Link>
                <Nav.Link href="#link">Roadmap</Nav.Link>
                <Nav.Link href="#link">Team</Nav.Link>
                <Nav.Link href="#link">FAQs</Nav.Link>
                <Nav.Link
                  href="https://discord.gg/b25BH3pmVg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={discordLogo}
                    alt="Discord"
                    className="circle-stroke"
                  />
                </Nav.Link>
                <Nav.Link
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={twitterLogo}
                    alt="Twitter"
                    className="circle-stroke"
                  />
                </Nav.Link>
                {/* <Nav.Link>
                  <Wallet />
                </Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Navigation;
