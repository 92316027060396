import * as React from "react";
import { ProgressBar, Card, Badge } from "react-bootstrap";

interface AttributesProps {}

interface AttributesState {}

class Attributes extends React.Component<AttributesProps, AttributesState> {
  render() {
    return (
      <Card className="container attribute-section-card mb-section">
        <Card.Body className="p-0">
          <Card.Title>
            <h2 className="mb-4">Attribute Distribution</h2>
          </Card.Title>

          <Card.Text>
            <div className="mb-5 text-20 kerning-2 text-white-secondary">
              No two idols are meant to be alike. Each represent a unique
              quality that truly differentiates it from the rest, with some
              characteristics being more rare than others. With more than 120+
              special attributes, your idol is unique to you.
            </div>
            {/* <div className="container px-0 mb-5">
              <div className="row gx-5">
                <div className="col">
                  <Badge pill className="text-common" bg="other">
                    Common
                  </Badge>
                </div>
                <div className="col">
                  <Badge pill className="text-rare" bg="other">
                    Common
                  </Badge>
                </div>
                <div className="col">
                  <Badge pill className="text-extremely-rare" bg="other">
                    Common
                  </Badge>
                </div>
              </div>
            </div> */}
            <div className="d-inline-flex flex-wrap mb-4">
              <Badge pill className="mr-3 text-common mb-3" bg="other">
                Common
              </Badge>
              <Badge pill className="mr-3 text-rare mb-3" bg="other">
                Rare
              </Badge>
              <Badge pill className="mr-3 text-extremely-rare mb-3" bg="other">
                Extremely Rare
              </Badge>
            </div>
          </Card.Text>

          <div className="container attribute-card">
            <div className="row gy-4">
              <div className="col-6">
                <Card>
                  <Card.Body className="background-muted-black p-4">
                    <Card.Subtitle className="mb-3 text-white-muted">
                      Male/Female
                    </Card.Subtitle>
                    <Card.Title className="mb-5">
                      🤲 Skin: 14 Attributes
                    </Card.Title>

                    <ProgressBar className="mb-4">
                      <ProgressBar variant="common" now={4890} key={1} />
                      <ProgressBar variant="extremely-rare" now={110} key={2} />
                    </ProgressBar>

                    <Card.Text className="text-white-secondary">
                      Notables: <i>Cyborg, Zombie, Ghost, Astronaut</i>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-6">
                <Card>
                  <Card.Body className="background-muted-black p-4">
                    <Card.Subtitle className="mb-3 text-white-muted">
                      Male/Female
                    </Card.Subtitle>
                    <Card.Title className="mb-5">
                      💇🏻‍♂️ Hairstyle: 30 Attributes
                    </Card.Title>

                    <ProgressBar className="mb-4">
                      <ProgressBar variant="common" now={3000} key={1} />
                      <ProgressBar variant="rare" now={1500} key={2} />
                      <ProgressBar variant="extremely-rare" now={500} key={3} />
                    </ProgressBar>

                    <Card.Text className="text-white-secondary">
                      Notables:{" "}
                      <i>Slickback Light Pink, Double Braid Platinum</i>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-12">
                <Card>
                  <Card.Body className="background-muted-black p-4">
                    <Card.Subtitle className="mb-3 text-white-muted">
                      Male/Female
                    </Card.Subtitle>
                    <Card.Title className="mb-5">
                      🧥 Outfit: 20 Attributes
                    </Card.Title>

                    <ProgressBar className="mb-4">
                      <ProgressBar variant="common" now={4400} key={1} />
                      <ProgressBar variant="rare" now={400} key={2} />
                      <ProgressBar variant="extremely-rare" now={200} key={3} />
                    </ProgressBar>

                    <Card.Text className="text-white-secondary">
                      Notables: <i>Korean Hanbok</i>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-6">
                <Card>
                  <Card.Body className="background-muted-black p-4">
                    <Card.Subtitle className="mb-3 text-white-muted">
                      Unisex
                    </Card.Subtitle>
                    <Card.Title className="mb-5">
                      👀 Eye Color: 8 Attributes
                    </Card.Title>

                    <ProgressBar className="mb-4">
                      <ProgressBar variant="common" now={7000} key={1} />
                      <ProgressBar variant="rare" now={2800} key={2} />
                      <ProgressBar variant="extremely-rare" now={200} key={3} />
                    </ProgressBar>

                    <Card.Text className="text-white-secondary">
                      Notables: <i>Cyan, Split Red Blue</i>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-6">
                <Card>
                  <Card.Body className="background-muted-black p-4">
                    <Card.Subtitle className="mb-3 text-white-muted">
                      Unisex
                    </Card.Subtitle>
                    <Card.Title className="mb-5">
                      👓 Eyewear: 6 Attributes
                    </Card.Title>

                    <ProgressBar className="mb-4">
                      <ProgressBar variant="common" now={7000} key={1} />
                      <ProgressBar variant="rare" now={2350} key={2} />
                      <ProgressBar variant="extremely-rare" now={650} key={3} />
                    </ProgressBar>

                    <Card.Text className="text-white-secondary">
                      Notables: <i>Snowboarding Goggles, Face Glitter</i>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-6">
                <Card>
                  <Card.Body className="background-muted-black p-4">
                    <Card.Subtitle className="mb-3 text-white-muted">
                      Unisex
                    </Card.Subtitle>
                    <Card.Title className="mb-5">
                      🧣 Neckwear: 6 Attributes
                    </Card.Title>

                    <ProgressBar className="mb-4">
                      <ProgressBar variant="common" now={8750} key={1} />
                      <ProgressBar variant="rare" now={900} key={2} />
                      <ProgressBar variant="extremely-rare" now={350} key={3} />
                    </ProgressBar>

                    <Card.Text className="text-white-secondary">
                      Notables: <i>Gold Chain, Diamond Chain</i>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-6">
                <Card>
                  <Card.Body className="background-muted-black p-4">
                    <Card.Subtitle className="mb-3 text-white-muted">
                      Unisex
                    </Card.Subtitle>
                    <Card.Title className="mb-5">
                      💍 Earring: 9 Attributes
                    </Card.Title>

                    <ProgressBar className="mb-4">
                      <ProgressBar variant="common" now={8000} key={1} />
                      <ProgressBar variant="rare" now={1550} key={2} />
                      <ProgressBar variant="extremely-rare" now={450} key={3} />
                    </ProgressBar>

                    <Card.Text className="text-white-secondary">
                      Notables: <i>Stud Diamond, Cross Diamond</i>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-6">
                <Card>
                  <Card.Body className="background-muted-black p-4">
                    <Card.Subtitle className="mb-3 text-white-muted">
                      Unisex
                    </Card.Subtitle>
                    <Card.Title className="mb-5">
                      👩‍🎤 Role: 7 Attributes
                    </Card.Title>

                    <ProgressBar className="mb-4">
                      <ProgressBar variant="common" now={5999} key={1} />
                      <ProgressBar variant="rare" now={1500} key={2} />
                      <ProgressBar variant="extremely-rare" now={100} key={3} />
                    </ProgressBar>

                    <Card.Text className="text-white-secondary">
                      Notables: <i>Leader, Maknae</i>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-6">
                <Card>
                  <Card.Body className="background-muted-black p-4">
                    <Card.Subtitle className="mb-3 text-white-muted">
                      Unisex
                    </Card.Subtitle>
                    <Card.Title className="mb-5">
                      🌆 Background: 5 Attributes
                    </Card.Title>

                    <ProgressBar className="mb-4">
                      <ProgressBar variant="common" now={7500} key={1} />
                      <ProgressBar variant="rare" now={1500} key={2} />
                      <ProgressBar
                        variant="extremely-rare"
                        now={1000}
                        key={3}
                      />
                    </ProgressBar>

                    <Card.Text className="text-white-secondary">
                      Notables: <i>Hollywood Bowl</i>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              {/* <div className="col-12">
                <Card>
                  <Card.Body className="background-muted-black p-4">
                    <Card.Subtitle className="mb-3 text-white-muted">
                      Unisex
                    </Card.Subtitle>
                    <Card.Title className="mb-5">🎉 Notables: Extra</Card.Title>

                    <Card.Text className="text-white-secondary">
                      Notables:{" "}
                      <i>
                        IU, Kim Jong Un, Jennie, Psy, Squid Game Worker, G
                        Dragon
                      </i>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div> */}
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default Attributes;
