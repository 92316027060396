import * as React from "react";
import logo from "../Images/logo.png";
import discordLogo from "../Images/discord-logo.svg";
import twitterLogo from "../Images/twitter-logo.svg";

interface FooterProps {}

interface FooterState {}

class Footer extends React.Component<FooterProps, FooterState> {
  render() {
    return (
      <React.Fragment>
        <div className="footer container" id="footer">
          <div className="container background-muted-footer-blue p-5 to-right-edge">
            <div className="d-flex flex-wrap justify-content-between align-items-top pb-4">
              <div className="mb-3">
                <img
                  alt="Logo"
                  src={logo}
                  width="60px"
                  className="align-top mb-3"
                />
                <h4>10,000 aspiring idols from around the globe</h4>
              </div>
              <div>
                <span className="mr-3 mb-3">Join the community here</span>
                <a
                  href="https://twitter.com/blimatechange"
                  className="mr-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={discordLogo}
                    alt="Discord"
                    className="circle-stroke"
                  />
                </a>
                <a
                  href="https://twitter.com/blimatechange"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={twitterLogo}
                    alt="Twitter"
                    className="circle-stroke"
                  />
                </a>
              </div>
            </div>

            <div className="d-inline-flex flex-wrap legal">
              <div className="legal mr-4">
                2020 Pixel Idol Collective. All Right Reserved
              </div>
              <a href="/terms">Terms and Conditions</a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
