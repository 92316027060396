import * as React from "react";

interface RoadmapProps {}

interface RoadmapState {}

class Roadmap extends React.Component<RoadmapProps, RoadmapState> {
  render() {
    return (
      <div className="roadmap mb-section container-fluid px-0">
        <div className="container">
          <div className="row align-items-start mb-5 pt-6">
            <div className="col-lg-3 col-12">
              <h2 className="mb-4">Roadmap</h2>
            </div>
            <div className="col-lg-9 col-12">
              <h4 className="mb-4 kerning-2">
                We’ve got a lot in store for the future of the Pixel Idol
                Collective.
              </h4>
              <h4 className="kerning-2">
                We want the community to help dictate and determine which
                features we should be focusing on next - so all you fucks will be
                heavily involved in our future!
              </h4>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-start mb-2">
            <div className="col">
              <div className="roadmapCard background-muted-green px-4 py-5 mr-5 mb-4 d-flex align-items-start">
                <div className="numberCircle text-black mr-3 ml-neg-5">
                  <span>1</span>
                </div>
                <div className="conatiner">
                  <div className="row">
                    <div className="col-12 col-lg-3">
                      <div className="roadmap-title mb-3">Initial Launch</div>
                    </div>
                    <div className="col-12 col-lg-9">
                      <div className="roadmap-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse vel congue erat, at convallis erat. Vivamus
                        placerat vel risus vitae molestie. In fringilla
                        elementum nisi, nec porttitor enim placerat sed. In quis
                        lacus velit.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="roadmapCard background-muted-orange px-4 py-5 ml-5 mb-4 d-flex align-items-start">
                <div className="numberCircle text-black mr-3 ml-neg-5">
                  <span>2</span>
                </div>
                <div className="conatiner">
                  <div className="row">
                    <div className="col-12 col-lg-3">
                      <div className="roadmap-title mb-3 no-wrap">
                        IdolsDAO Launch
                      </div>
                    </div>
                    <div className="col-12 col-lg-9">
                      <div className="roadmap-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse vel congue erat, at convallis erat. Vivamus
                        placerat vel risus vitae molestie. In fringilla
                        elementum nisi, nec porttitor enim placerat sed. In quis
                        lacus velit.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="roadmapCard background-muted-teal px-4 py-5 mr-5 mb-4 d-flex align-items-start">
                <div className="numberCircle text-black mr-3 ml-neg-5">
                  <span>3</span>
                </div>
                <div className="conatiner">
                  <div className="row">
                    <div className="col-12 col-lg-3 flex-nowrap">
                      <div className="roadmap-title mb-3">
                        Release of Pixel Idol Music Platform
                      </div>
                    </div>
                    <div className="col-12 col-lg-9">
                      <div className="roadmap-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse vel congue erat, at convallis erat. Vivamus
                        placerat vel risus vitae molestie. In fringilla
                        elementum nisi, nec porttitor enim placerat sed. In quis
                        lacus velit.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="roadmapCard background-muted-purple px-4 py-5 ml-5 mb-neg-6 d-flex align-items-start">
                <div className="numberCircle text-black mr-3 ml-neg-5">
                  <span>4</span>
                </div>
                <div className="conatiner">
                  <div className="row">
                    <div className="col-12 col-lg-3 flex-nowrap">
                      <div className="roadmap-title mb-3">
                        Collaborations &amp; Airdrops
                      </div>
                    </div>
                    <div className="col-12 col-lg-9">
                      <div className="roadmap-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse vel congue erat, at convallis erat. Vivamus
                        placerat vel risus vitae molestie. In fringilla
                        elementum nisi, nec porttitor enim placerat sed. In quis
                        lacus velit.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Roadmap;
