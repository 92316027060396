import * as React from "react";
import Wallet from "../Components/Wallet";

interface HeaderProps {}

interface HeaderState {}

class Header extends React.Component<HeaderProps, HeaderState> {
  //state = { :  }

  render() {
    return (
      <div className="container header mb-section mt-6">
        <h1 className="mb-6">
          Pixel Idol Collective brings together <br /> 10,000 aspiring idols
          from around the 🌎. Watch them perform on the global stage! XXX
        </h1>

        {/* <h2 className="mb-7 text-pink">Releases November 10, 7PM PST.</h2> */}

        {/* <Form className="mb-7">
          <Row className="align-items-center">
            <Col xs="auto" className="my-1">
              <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                Amount
              </Form.Label>
              <Form.Control
                className="mb-2"
                id="inlineFormInput"
                placeholder="Number"
                defaultValue="1"
              />
            </Col>
            <Col xs="auto" className="my-1">
              
            </Col>
          </Row>
        </Form> */}

        <div className="mb-5">
          <Wallet />
        </div>

        <div className="d-inline-flex pb-5 flex-wrap flex-lg-nowrap">
          <div className="benefit d-inline-flex align-items-center mr-5 mb-4">
            <div className="emojiCircle background-muted-red mr-3 mb-3">
              <span>💫</span>
            </div>
            <h5>
              <b>Unique in every right.</b>{" "}
              <span className="text-white-muted">
                Each idol is chosen from a pool of over 100,000 prospective
                competitors with different talents, styles, and personalities.
              </span>
            </h5>
          </div>

          <div className="benefit d-inline-flex align-items-center mb-4">
            <div className="emojiCircle background-muted-blue mr-3 mb-3">
              <span>💛</span>
            </div>
            <h5>
              <b>Community at the heart.</b>{" "}
              <span className="text-white-muted">
                We have plans build a Pixel Idol ecosystem comprised of
                interactivity and utility, rewards, collaboration with brands,
                and much more!
              </span>
            </h5>
          </div>
        </div>

        <div>Placeholder for images</div>
      </div>
    );
  }
}

export default Header;
