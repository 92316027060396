import * as React from "react";
import yellowShape from "../Images/yellow-shape.png";

interface FAQProps {}

interface FAQState {}

class FAQ extends React.Component<FAQProps, FAQState> {
  render() {
    return (
      <div className="container faq mb-section d-flex align-items-start justify-content-between">
        <div className="container" id="faq-container">
          <div className="row">
            <div className="col-1 col-lg-2 faq-image">
              <img src={yellowShape} alt="FAQ" className="" />
            </div>
            <div className="col-12 col-lg-8">
              <h2 className="mb-5 mt-5">FAQs</h2>
              <div className="row align-items-start gx-5 gy-5">
                <div className="col-12 col-md-6">
                  <div className="question mb-3">What is the total supply?</div>
                  <div className="answer text-white-secondary">
                    Suspendisse vel congue erat, at convallis erat. Vivamus
                    placerat vel risus vitae molestie. In fringilla elementum
                    nisi, nec porttitor enim placerat sed.
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="question mb-3">
                    How much will it cost to mint an NFT?
                  </div>
                  <div className="answer text-white-secondary">
                    Suspendisse vel congue erat, at convallis erat. Vivamus
                    placerat vel risus vitae molestie. In fringilla elementum
                    nisi, nec porttitor enim placerat sed.
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="question mb-3">
                    Do you have a rarity system?
                  </div>
                  <div className="answer text-white-secondary">
                    Suspendisse vel congue erat, at convallis erat. Vivamus
                    placerat vel risus vitae molestie. In fringilla elementum
                    nisi, nec porttitor enim placerat sed.
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="question mb-3">
                    Will there be a secondary market?
                  </div>
                  <div className="answer text-white-secondary">
                    Yes, we will be listing on Solanart or Digital Eyes as soon
                    as we go live so you can trade immediately.
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="question mb-3">
                    Will there be a community fund?
                  </div>
                  <div className="answer text-white-secondary">
                    Suspendisse vel congue erat, at convallis erat. Vivamus
                    placerat vel risus vitae molestie. In fringilla elementum
                    nisi, nec porttitor enim placerat sed.
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="question mb-3">When is the mint date?</div>
                  <div className="answer text-white-secondary">
                    Suspendisse vel congue erat, at convallis erat. Vivamus
                    placerat vel risus vitae molestie. In fringilla elementum
                    nisi, nec porttitor enim placerat sed.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
