import "./App.css";
import Header from "./Components/Header";
import Navbar from "./Components/Navbar";

import Attributes from "./Components/Attributes";
import Roadmap from "./Components/Roadmap";
import Team from "./Components/Team";
import FAQ from "./Components/FAQ";
import Footer from "./Components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Attributes />
      <Roadmap />
      <Team />
      <FAQ />
      <Footer />
    </div>
  );
};

export default App;
