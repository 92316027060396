import * as React from "react";
import may from "../Images/may.png";
import jb from "../Images/jb.png";
import json from "../Images/json.png";
import blacknut from "../Images/blacknut.png";
// import Card from "react-bootstrap";

interface TeamProps {}

interface TeamState {}

class Team extends React.Component<TeamProps, TeamState> {
  render() {
    return (
      <div className="container team-mb-section" id="team-container">
        <h2 className="text-align-center mb-10">The Team</h2>

        <div className="container">
          <div className="row gy-6 align-items-start">
            <div className="col-12 col-lg-5 p-4 container team-card">
              <div className="row">
                <div className="col">
                  <div className="team-member-name text-blue">JB</div>
                  <div className="team-member-job text-white-secondary">
                    Project Lead, Developer
                  </div>
                </div>
                <div className="col ml-auto">
                  <img
                    src={jb}
                    alt="thumbnail"
                    className="mt-neg-8 float-right"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-5 p-4 container team-card">
              <div className="row">
                <div className="col">
                  <div className="team-member-name text-red">JSON</div>
                  <div className="team-member-job text-white-secondary">
                    Artist &amp; Designer
                  </div>
                </div>
                <div className="col ml-auto">
                  <img
                    src={json}
                    alt="thumbnail"
                    className="mt-neg-8 float-right"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-5 p-4 container team-card">
              <div className="row">
                <div className="col">
                  <div className="team-member-name text-red">May</div>
                  <div className="team-member-job text-white-secondary">
                    Community Manager
                  </div>
                </div>
                <div className="col ml-auto">
                  <img
                    src={may}
                    alt="thumbnail"
                    className="mt-neg-8 float-right"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-5 p-4 container team-card">
              <div className="row">
                <div className="col">
                  <div className="team-member-name text-blue">Black Nut</div>
                  <div className="team-member-job text-white-secondary">
                    Developer
                  </div>
                </div>
                <div className="col ml-auto">
                  <img
                    src={blacknut}
                    alt="thumbnail"
                    className="mt-neg-8 float-right"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
